// paypalPlugin.js

const PayPalPlugin = {
    install(Vue, options) {
        // 加载PayPal脚本
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${options.clientId}&currency=${options.currency}`;
        script.async = true;
        document.body.appendChild(script);

        // 等待PayPal脚本加载完成后，再将PayPal对象挂载到Vue原型上
        script.onload = () => {
            Vue.prototype.$paypal = window.paypal;
        };
    },
};

export default PayPalPlugin;
