import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dier',
    component: () => import('../views/yuangong/homeView.vue'),
    meta: {
      title: 'Noveleve Freenovel!!', // 设置页面标题
      metaTags: [
        {
          name: 'keywords', // 设置搜索关键字
          content: 'noveleve,freenovel,free,eve,sex',
        },
        {
          name: 'description', // 设置 Meta 描述
          content: 'Explore a mysterious fantasy world and pursue adventure and magic in the novel "Fantasy Journey". Follow the protagonists journey, unravel ancient mysteries, and experience a thrilling fantasy journey. Read more and start your fantasy journey!',
        },
      ],
    },
  },
  {
    path: '/dier',
    name: 'dier',
    component: () => import('../views/yuangong/homeView.vue'),
    meta: {
      title: 'Noveleve Freenovel!!', // 设置页面标题
      metaTags: [
        {
          name: 'keywords', // 设置搜索关键字
          content: 'noveleve,freenovel,free,eve,sex',
        },
        {
          name: 'description', // 设置 Meta 描述
          content: 'Explore a mysterious fantasy world and pursue adventure and magic in the novel "Fantasy Journey". Follow the protagonists journey, unravel ancient mysteries, and experience a thrilling fantasy journey. Read more and start your fantasy journey!',
        },
      ],
    },

  },
  {
    path: '/ys',
    name: 'ys',
    component: () => import('../views/text/text-new.vue')
  },
  {
    path: '/fenleiNew',
    name: 'fenleiNew',
    component: () => import('../views/text/fenleiNew.vue'),
  },
  {
    path: '/fenlei',
    name: 'fenlei',
    component: () => import('../views/yuangong/fenleiView.vue'),
    meta: {
      title: 'Noveleve', // 设置页面标题
    }
  },
  {
    path: '/mybooks',
    name: 'mybooks',
    component: () => import('../views/yuangong/mybooksView.vue'),
    meta: {
      title: 'Noveleve', // 设置页面标题
    }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/yuangong/userView.vue'),
    meta: {
      title: 'Noveleve', // 设置页面标题
    }
  },
  {
    path: '/usercq',
    name: 'usercq',
    component: () => import('../views/yuangong/usercqView.vue'),
    meta: {
      title: 'Noveleve', // 设置页面标题
    }
  },
  {
    path: '/booksView',
    name: 'booksView',
    component: () => import('../views/yuangong/booksView.vue')
  },
  {
    path: '/chatView',
    name: 'chatView',
    component: () => import('../views/yuangong/chatView.vue')
  },
  {
    path: '/loginView',
    name: 'loginView',
    component: () => import('../views/yuangong/loginView.vue')
  },
  {
    path: '/loginzcView',
    name: 'loginzcView',
    component: () => import('../views/yuangong/loginzcView.vue')
  },
  {
    path: '/usercqcgView',
    name: 'usercqcgView',
    component: () => import('../views/yuangong/usercqcgView.vue')
  }
]

const router = new VueRouter({
  routes
})

// 在路由切换时动态设置页面标题和 meta 标签
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Noveleve';
  // 动态添加 meta 标签
  const metaTags = to.meta.metaTags || [];
  metaTags.forEach(tag => {
    const meta = document.createElement('meta');
    meta.setAttribute('name', tag.name);
    meta.setAttribute('content', tag.content);
    document.head.appendChild(meta);
  });
  next();
});

export default router
