import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex)
// 全局参数设置
const store = new Vuex.Store({
    state: {
        count: 0,
        vulas: '',
        sous: '',
        paix: "",
        y: 0,
        zhome: '01'
    },
    mutations: {
        setVulas(state, value) {
            state.vulas = value
        },
        setsous(state, sous) {
            state.sous = sous
        },
        setpaix(state, paix) {
            state.paix = paix
        },
        setxyzindex(state, y) {
            state.y = y;
        },
        setzhome(state, zhome) {
            state.zhome = zhome;
        }
    }
})
export default store