import Vue from 'vue'
import App from './App.vue'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import PayPalPlugin from './paypal/paypalPlugin'; // 导入刚才创建的插件
//路由
import router from './router'
//全局变量引用
import store from './vuex';
// 端口号密钥
import axios from 'axios'
// 谷歌登录
import GSignInButton from 'vue-google-signin-button'
Vue.use(GSignInButton);
// ajiakesi 服务登录器(测试服务器)
// axios.defaults.baseURL = "http://localhost:8080"
//正式服务器
axios.defaults.baseURL = "http://54.157.143.165:8080"
Vue.prototype.$http = axios

Vue.config.productionTip = false
Vue.use(PayPalPlugin, {
  clientId: 'Acf9hJbkYkpq5lhKiFj9vMpFDEzaqPbUY6Nu8D5hP-NYwN8t1-HnDhd9_zfCJ1U6ROvSwPpZRwcIoEFF', // 替换为你的 PayPal 客户端 ID
  currency: 'USD',  // 替换为你希望使用的货币
});
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
